<template>
    <div class="">
        <section v-show="id_pedido === null" style="height:calc(100vh - 293px)">
            <div class="row mx-0 h-100 align-items-center justify-content-center">
                <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                        <img height="80" src="/img/no-imagen/click.svg" alt="" />
                    </div>
                    <p>Selecciona uno de los Pedidos activos del panel de la izquierda.</p>
                </div>
            </div>
        </section>
        <section v-show="id_pedido" class="h-100">
            <div class="border-bottom text-general p-2 text-center d-middle-center">
                <div class="br-10 mx-2 cr-pointer text-general" @click="imprimir">
                    <i class="icon-printer f-20" />
                </div>
                <p><b>ID. {{ pedido.id }} </b>Pedido Entregado</p>
            </div>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 285px);">
                <div class="border-bottom">
                    <div class="row mx-0 align-items-center my-2 px-2">
                        <img class="border rounded-circle bg-whitesmoke" :src="cliente.logo_mini ? cliente.logo_mini : '/img/no-imagen/sin_user.png'" width="50" height="50" alt="" />
                        <div class="col px-2">
                            <p class="f-15 f-600 text-general">{{ cliente.nombre }}</p>
                        </div>
                        <div class="br-20 px-2 mx-2 bg-light-f5 text-general2">
                            <i class="f-20 icon-receipt" />
                            <span class="mx-2">{{ cantidad }}</span>
                        </div>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center text-general">
                        <el-tooltip placement="bottom" content="Dirección" effect="light">
                            <i class="icon-location text-gris f-22 mr-2" />
                        </el-tooltip>
                        <p class="col f-13">{{ cliente.direccion }}</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center text-general">
                        <el-tooltip placement="botttom" content="Cobertura" effect="light">
                            <i class="icon-arrow-top-right text-gris f-22 mr-2" />
                        </el-tooltip>
                        <p class="col f-13">{{ pedido.distancia }} Mts</p>
                    </div>
                    <div class="row mx-0 my-2 px-2 align-items-center text-general">
                        <el-tooltip placement="bottom" content="Fecha Entrega" effect="light">
                            <i class="icon-calendar-clock text-gris f-22 mr-2" />
                        </el-tooltip>
                        <p class="col f-13">{{ pedido.entrega_fecha | helper-fecha('ddd DD MMMM') }}</p>
                    </div>
                    <div v-show="pedido.estado != 1" class="row mx-0 my-2 px-2 align-items-center text-general">
                        <el-tooltip placement="bottom" content="Valor Domicilio" effect="light">
                            <i class="icon-truck-fast-outline text-gris f-22 mr-2" />
                        </el-tooltip>
                        <p class="col f-13">{{ convertMoney(pedido.valor_envio, pedido.idm_moneda) }}</p>
                    </div>
                    <div v-show="pedido.estado != 1" class="row mx-0 my-2 px-2 align-items-center">
                        <img class="border rounded-circle bg-whitesmoke" :src="pedido.foto_responsable" width="50" height="50" alt="" />
                        <p class="col f-14 text-general">{{ pedido.responsable || 'Sin Asignar' }} <br /> <i class="f-12">Responsable</i> </p>
                    </div>
                    <div class="row ml-3 mr-0 d-middle">
                        <span class="text-general mr-2">Creditos</span>
                        <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                            <i class="icon-clock text-general2 f-18" />
                        </el-tooltip>
                        <span class="f-14 mr-2 text-general">{{ convertMoney(pedido.saldo_vigente,pedido.idm_moneda) }}</span>

                        <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                            <i class="icon-attention-alt text-general-red f-18" />
                        </el-tooltip>
                        <span class="f-14 text-general">{{ convertMoney(pedido.saldo_vencido,pedido.idm_moneda) }}</span>
                    </div>
                </div>
                <!-- linea de tiempo -->
                <div class="border-bottom py-2">
                    <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                        <div class="col-auto">
                            <div class="rounded-circle position-relative" :class="act.activo?'bg-gr-general':'bg-gris'" style="width:44px;height:44px;padding:1px">
                                <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                    <i :class="`f-20 ${act.icon} ${act.activo?'text-gr-general':'text-gris'}`" />
                                </div>
                            </div>
                            <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-gr-general':'bg-gris'" style="width:2px;" />
                        </div>
                        <div class="col">
                            <div class="row mx-0">
                                <div class="col px-1">
                                    <p :class="act.activo?'text-primary':'text-gris'">{{ act.content }}</p>
                                    <p class="f-15">{{ act.hora | helper-fecha('hh:mm a') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-bottom py-2">
                    <div class="row mx-0 px-2 cr-pointer">
                        <i class="icon-card-text-outline f-20 text-general2" />
                        <div class="col my-auto text-general">
                            Instrucciones de entrega
                        </div>
                    </div>
                    <div class="row px-3">
                        <div class="col f-13 text-general px-4">
                            <p v-html="fun_caracteres(pedido.instrucciones)" />
                        </div>
                    </div>
                </div>
                <div class="p-2 text-general">
                    <div class="row mx-0 px-2 f-600 f-20 justify-content-between">
                        <p class="text-general">Resumen</p>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Costo de productos ({{ resumen.cantidad_productos }})</p>
                        <b>{{ convertMoney(resumen.val_productos,pedido.idm_moneda) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Costo de envío</p>
                        <b>{{ convertMoney(resumen.val_domicilios,pedido.idm_moneda) }}</b>
                    </div>
                    <div class="row mx-0 px-2 justify-content-between">
                        <p>Descuento</p>
                        <b class="text-general">
                            {{ convertMoney(resumen.val_descuentos,pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div v-if="resumen.id_cupon" class="row mx-0 px-2 justify-content-between">
                        <div class="d-middle">
                            <i class="icon-cupon pr-1" />
                            <p>{{ resumen.codigo_cupon }}</p>
                        </div>
                        <b class="text-general-red">
                            {{ convertMoney(resumen.val_cupon, pedido.idm_moneda) }}
                        </b>
                    </div>
                    <div class="row mx-0 px-2 mt-2 f-600 justify-content-between">
                        <p>Total a cobrar</p>
                        <p>{{ convertMoney(resumen.val_total,pedido.idm_moneda) }}</p>
                    </div>
                </div>
                <div v-show="pedido.pago_estado === 2" class="row mx-0 mt-3 mb-3 px-1">
                    <div class="col-12 px-0">
                        <div class="bg-general text-white header-credito d-middle-center f-600 f-15">
                            Crédito
                        </div>
                        <div class="borders pt-3" style="min-height:150px;">
                            <p class="text-muted mb-2 f-15 f-600 text-center">
                                Cuotas establecidas
                            </p>
                            <div v-for="(cuota, c) in cuotas" :key="c" class="row mx-3" :class="colorTextoCredito(cuota)">
                                <p class="tres-puntos border-bottom border-top py-2 col">
                                    <i class="f-18" :class="iconCredito(cuota)" />
                                    <span class="f-15">
                                        {{ cuota.fecha | helper-fecha('DD/MMM/YYYY') }}
                                    </span>
                                </p>
                                <p class="tres-puntos border-bottom border-top py-2 col-auto f-600">
                                    {{ convertMoney(cuota.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                            <p class="text-muted my-2 f-15 f-600 text-center">
                                Historial de Pagos
                            </p>
                            <div v-for="(p, i) in pagos" :key="`key2-${i}`" class="row mx-3 border-bottom mb-3">
                                <p class="col f-14 tres-puntos">
                                    {{ p.created_at | helper-fecha('DD MMMM YYYY') }}
                                </p>
                                <div class="col-auto">
                                    <el-tooltip placement="bottom" :content="p.comentario" effect="light">
                                        <i class="icon-attention-alt f-18 text-gr-general" />
                                    </el-tooltip>
                                </div>
                                <p class="col-12 f-14">
                                    {{ convertMoney(p.valor,pedido.idm_moneda) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal-imprimir ref="imprimirRecibo" />
        </section>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import PdfPedidos from '~/services/pdf/pdf'

export default {
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
            cliente: 'pedidos/pedidos_admin/cliente',
            historial: 'pedidos/pedidos_admin/historial',
            resumen: 'pedidos/pedidos_admin/resumen',
            cuotas: 'pedidos/pedidos_admin/cuotas',
            pagos: 'pedidos/pedidos_admin/pagos',

        }),
        cantidad(){
            let cant = this.cliente.cant_pedidos
            if(cant === 0){
                return 0
            }else if(cant > 0 && cant <= 5){
                return '1 - 5'
            }
            else if(cant > 5 && cant <= 20){
                return '+5'
            }
            else if(cant > 20 && cant <= 100){
                return '+20'
            }
            else if(cant > 100){
                return '+100'
            }else{
                return 0
            }
        }
    },
    methods: {
        mostar_credito(){
            this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
            this.pedido.pago_estado = 2
        },
        colorTextoCredito(cuota){
            if(cuota.valor_pendiente == 0){
                return 'text-primary'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'text-red'
            }
            return ''
        },
        iconCredito(cuota){

            if(cuota.valor_pendiente == 0){
                return 'icon-ok-circled-outline text-green'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'icon-attention-alt text-red'
            }

            return ''
        },
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },
        fun_caracteres(cadena){
            if(!cadena) return
            let total = cadena.length
            // if(this.ver_mas){
            //     return this.formatearTextoHtml(cadena.substr(0,total))
            // }
            return this.formatearTextoHtml(cadena.substr(0,300))
        },
    }
}
</script>

<style lang="css" scoped>
.br-12{
    border-radius: 12px;
}
.header-credito{
    border-radius: 12px 12px 0 0px;
    height: 50px;
}
.borders{
    border-left: 1px solid #5D5D5D59;
    border-right: 1px solid #5D5D5D59;
    border-bottom: 1px solid #5D5D5D59;
    border-radius: 0px 0px 12px 12px;
}
.text-red{ color: #FF2525; }
.text-green{ color: #28D07B; }
</style>
